<template>
  <v-container fluid class="px-0">
    <ContactUsForm />
  </v-container>
</template>

<script>
import ContactUsForm from "@/components/layout/contact/ContactUsForm";
export default {
  name: "ContactForm",
  components: { ContactUsForm }
};
</script>

<style scoped></style>
