<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-card>
      <v-card-title>
        <div class="headline">{{ $t("contact_heading") }}</div>
      </v-card-title>
      <v-card-subtitle>
        <div class="subtitle-1">{{ $t("contact_subheading") }}</div>
      </v-card-subtitle>
      <v-card-text>
        <form @submit.prevent="submit">
          <v-row no-gutters>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="inquiryType"
                rules="required"
              >
                <v-radio-group
                  v-model="inquiryType"
                  required
                  :error-messages="errors"
                >
                  <template v-slot:label>
                    <div class="font-weight-medium main--text text-heading-4">
                      {{ $t("contact_inquiry_type") }}
                    </div>
                  </template>
                  <v-radio
                    v-for="item in concerns"
                    :value="item.text"
                    :key="item.value"
                  >
                    <template v-slot:label>
                      {{ item.text }}
                    </template>
                  </v-radio>
                </v-radio-group>
              </validation-provider>
            </v-col>

            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="message"
                rules="required"
              >
                <v-textarea
                  v-model="message"
                  :error-messages="errors"
                  required
                  outlined
                  :label="$t('contact_message')"
                  :placeholder="$t('contact_message')"
                ></v-textarea>
              </validation-provider>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" :disabled="invalid" block color="primary">{{
                $t("contact_send")
              }}</v-btn>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </validation-observer>
</template>

<script>
export default {
  name: "ContactUsForm",
  data() {
    return {
      message: "",
      inquiryType: "",
      concerns: [
        {
          value: "concern1",
          text: this.$t("contact_concern1")
        },
        {
          value: "concern2",
          text: this.$t("contact_concern2")
        },
        {
          value: "concern3",
          text: this.$t("contact_concern3")
        },
        {
          value: "concern4",
          text: this.$t("contact_concern4")
        },
        {
          value: "concern5",
          text: this.$t("contact_concern5")
        },
        {
          value: "concern6",
          text: this.$t("contact_concern6")
        },
        {
          value: "concern7",
          text: this.$t("contact_concern7")
        }
      ]
    };
  },
  methods: {
    submit() {
      this.$refs.observer.validate();

      this.loading = true;

      let data = {
        type: this.inquiryType,
        message: this.message,
        referrer_url: localStorage.getItem("previousUrl")
      };

      this.$store
        .dispatch("CONTACT_SUBMIT", data)
        .then(() => {
          this.$store.commit("showAlert", {
            text: this.$t("contact_success_message"),
            successStatus: true
          });
          this.message = "";
          this.inquiryType = "";
          this.$refs.observer.reset();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
